<script setup lang="ts">
import type { Schemas } from "#shopware";
import { ApiClientError } from "@shopware/api-client";
import useVuelidate from "@vuelidate/core";
import { customValidators } from "~~/i18n/utils/i18n-validators";
import SharedSelectableCardGroup from "../shared/SharedSelectableCardGroup.vue";

const props = defineProps<{
  recalculatePrizes: () => void;
}>();

const { t } = useI18n();

const { isShipmentSpedi } = useCart();

const {
  setActiveBillingAddress,
  setActiveShippingAddress,
  activeShippingAddress,
  activeBillingAddress,
  refreshSessionContext,
} = useSessionContext();
const {
  register,
  isLoggedIn,
  isGuestSession,
  defaultBillingAddressId,
  defaultShippingAddressId,
} = useUser();
const { required, requiredIf, email, numeric } = customValidators();
const {
  createCustomerAddress,
  loadCustomerAddresses,
  customerAddresses,
  updateCustomerAddress,
} = useAddress();
const { pushError } = useNotifications();

const sharedRules = {
  salutationId: {
    required: requiredIf(() => !isLoggedIn.value && !isGuestSession.value),
  },
  company: {},
  email: {
    required: requiredIf(() => !isLoggedIn.value && !isGuestSession.value),
    email,
  },
  firstName: {
    required,
  },
  lastName: {
    required,
  },
  street: {
    required,
  },
  zipcode: {
    required,
  },
  city: {
    required,
  },
  countryId: {
    required,
  },
  phoneNumber: {
    required: requiredIf(
      () => isShipmentSpedi() || (!isLoggedIn.value && !isGuestSession.value),
    ),
    numeric,
  },
  extraPhoneNumber: {
    required: requiredIf(
      () =>
        isShipmentSpedi() &&
        !activeBillingAddress.value?.phoneNumber &&
        !isLoggedIn,
    ),
    numeric,
  },
};

const billingAddress = reactive({
  id: undefined,
  salutationId: "",
  email: "",
  company: "",
  firstName: "",
  lastName: "",
  street: "",
  zipcode: "",
  city: "",
  countryId: "",
  phoneNumber: "",
  extraPhoneNumber: "",
});

const shippingAddress = reactive({
  id: undefined,
  salutationId: "",
  email: "",
  company: "",
  firstName: "",
  lastName: "",
  street: "",
  zipcode: "",
  city: "",
  countryId: "",
  phoneNumber: "",
  extraPhoneNumber: "",
});

const vb = useVuelidate(sharedRules, billingAddress);
const vs = useVuelidate(sharedRules, shippingAddress);

const { getCountries } = useCountries();

const newBillingAddress = ref(false);
const newShippingAddress = ref(false);
const differingShippingAddress = ref(false);

watch(differingShippingAddress, (newValue) => {
  if (newValue && !isLoggedIn.value) newShippingAddress.value = true;

  if (!newValue) {
    newShippingAddress.value = false;

    if (activeBillingAddress.value) {
      setActiveShippingAddress(activeBillingAddress.value);
    }
  }
});

if (!isLoggedIn.value) {
  newBillingAddress.value = true;

  if (isGuestSession.value && !!activeBillingAddress.value) {
    Object.assign(billingAddress, activeBillingAddress.value);

    if (
      !!activeShippingAddress.value &&
      activeBillingAddress.value.id != activeShippingAddress.value.id
    ) {
      newShippingAddress.value = true;
      Object.assign(shippingAddress, activeShippingAddress.value);
    }
  }
}

if (activeBillingAddress.value?.id != activeShippingAddress.value?.id) {
  differingShippingAddress.value = true;
}

const getCountryISOById = (countryId: string) => {
  return computed(() => {
    const country = getCountries.value.find((c) => c.id === countryId);
    return country ? country.iso : null;
  });
};

const addressValidationController = useModal(); // Modal for Entries
const addressSuggestions = ref([]); // API Entries
const addressToValidate = ref(); // Address for building API-URL
const cardGroup = ref<InstanceType<typeof SharedSelectableCardGroup> | null>(
  null,
);

async function proceedBillingAddress() {
  hasBillingValidationError.value = false;
  newBillingAddress.value = false;
  await saveAddresses();
}

const hasBillingValidationError = ref(false);
const hasShippingValidationError = ref(false);

async function addressValidation(isBillingAddress: boolean) {
  let url = "";
  // Billing Address
  if (isBillingAddress) {
    vb.value.$touch();
    const valid = await vb.value.$validate();
    if (!valid) {
      pushError(t("form.checkYourInputs"));
      return;
    }
    url = `/api/address/address-validation?zipCode=${billingAddress.zipcode}&city=${billingAddress.city}&street=${billingAddress.street}&countryIso=${getCountryISOById(billingAddress.countryId).value}`;
    isLoading["billingAddress"] = true;
    hasBillingValidationError.value = false;
    addressToValidate.value = billingAddress;
  }
  // Shipping Address
  else {
    vs.value.$touch();
    const valid = await vs.value.$validate();
    if (!valid) {
      pushError(t("form.checkYourInputs"));
      return;
    }
    url = `/api/address/address-validation?zipCode=${shippingAddress.zipcode}&city=${shippingAddress.city}&street=${shippingAddress.street}&countryIso=${getCountryISOById(shippingAddress.countryId).value}`;
    isLoading["shippinAddress"] = true;
    hasShippingValidationError.value = false;
    addressToValidate.value = shippingAddress;
  }

  // Response
  try {
    // Validation API Call
    const response = await fetch(url);
    const responseData = ref();
    let hasHno = false;

    try {
      responseData.value = await response.json();
      addressSuggestions.value = responseData.value;
    } catch {
      console.log("Error parsing response");
    }

    if ((addressSuggestions.value?.hno || "") == "") {
      hasHno = false;
    } else {
      hasHno = true;
    }

    // 200
    if (response.status === 200) {
      if (!hasHno) {
        if (isBillingAddress) {
          hasBillingValidationError.value = true;
          pushError(t("form.addressValidationErrorMissingHouseNumber"));
        } else {
          hasShippingValidationError.value = true;
          pushError(t("form.addressValidationErrorMissingHouseNumber"));
        }
      } else {
        addressValidationController.open();
      }
    }
    // 204
    else if (response.status === 204) {
      if (isBillingAddress) {
        await proceedBillingAddress();
        cardGroup?.value?.updateSelectedCard(billingAddress.id || "");
        isShowAll.value = true;
      } else {
        await proceedShippingAddress();
      }
    } else {
      if (isBillingAddress) {
        hasBillingValidationError.value = true;
      } else {
        hasShippingValidationError.value = true;
      }
      pushError(t("form.addressValidationError"));
    }
  } finally {
    isLoading["billingAddress"] = false;
    isLoading["shippinAddress"] = false;
  }
}

async function proceedShippingAddress() {
  hasShippingValidationError.value = false;
  newShippingAddress.value = false;
  await saveAddresses();
}

async function saveAddresses() {
  if (newBillingAddress.value) {
    vb.value.$touch();
    const valid = await vb.value.$validate();
    if (!valid) {
      pushError(t("form.checkYourInputs"));
      throw "";
    }
    newBillingAddress.value = false;
  } else if (newShippingAddress.value) {
    vs.value.$touch();
    const valid = await vs.value.$validate();
    if (!valid) {
      pushError(t("form.checkYourInputs"));
      throw "";
    }
    newShippingAddress.value = false;
  }

  try {
    let registered = false;
    if (!isLoggedIn.value && !isGuestSession.value) {
      registered = true;
      const guest = {
        acceptedDataProtection: true,
        billingAddress: billingAddress,
        email: billingAddress.email,
        firstName: billingAddress.firstName,
        lastName: billingAddress.lastName,
        guest: true,
      };
      // @ts-ignore
      await register(guest);
    }

    vb.value.$touch();
    const vbValid = await vb.value.$validate();

    vs.value.$touch();
    const vsValid = await vs.value.$validate();

    if (vbValid && !registered) {
      if (!billingAddress.id) {
        // @ts-ignore
        await createCustomerAddress(billingAddress).then((data) =>
          Object.assign(billingAddress, data),
        );
      } else {
        // @ts-ignore
        await updateCustomerAddress(billingAddress).then((data) =>
          Object.assign(billingAddress, data),
        );
      }
      setActiveBillingAddress(billingAddress);

      if (!differingShippingAddress.value) {
        setActiveShippingAddress(billingAddress);
      }

      newBillingAddress.value = false;
    }
    if (vsValid && !registered) {
      if (!shippingAddress.id) {
        // @ts-ignore
        await createCustomerAddress(shippingAddress).then((data) =>
          Object.assign(shippingAddress, data),
        );
        if (isLoggedIn.value) newShippingAddress.value = false;
      } else {
        // @ts-ignore
        await updateCustomerAddress(shippingAddress).then((data) =>
          Object.assign(shippingAddress, data),
        );
      }
      if (isLoggedIn.value) newShippingAddress.value = false;
      setActiveShippingAddress(shippingAddress);
    }
    await props?.recalculatePrizes();
  } catch (e) {
    if (e instanceof ApiClientError) {
      const errors = e.details.errors;
      pushError(errors[0].detail);
    }
    throw e;
  }

  await refreshSessionContext();
  await loadCustomerAddresses();
}

const isFormExpanded = computed(() => {
  return newBillingAddress.value || newShippingAddress.value;
});

const extraPhoneNumber = ref();
const needsExtraPhoneNumber = computed(
  () => isShipmentSpedi() && !activeBillingAddress.value?.phoneNumber,
);
watch(
  () => billingAddress.extraPhoneNumber,
  (newVal) => {
    extraPhoneNumber.value = newVal;
  },
);

defineExpose({
  saveAddresses,
  differingShippingAddress,
  isFormExpanded,
  needsExtraPhoneNumber,
  extraPhoneNumber,
});

const emit = defineEmits(["load"]);
loadCustomerAddresses().then(() => emit("load"));

const isLoading = reactive<{ [key: string]: boolean }>({});

async function setBillingAddress(addr: Schemas["CustomerAddress"]) {
  isLoading["billingAddress"] = true;
  const promises = [];

  newBillingAddress.value = false;
  promises.push(setActiveBillingAddress(addr));

  if (!differingShippingAddress.value) {
    newShippingAddress.value = false;
    promises.push(setActiveShippingAddress(addr));
  }

  await Promise.all(await promises);
  isLoading["billingAddress"] = false;
  await props?.recalculatePrizes();
}

async function setShippingAddress(addr: Schemas["CustomerAddress"]) {
  isLoading["shippinAddress"] = true;
  newShippingAddress.value = false;
  await setActiveShippingAddress(addr);
  isLoading["shippinAddress"] = false;
  await props?.recalculatePrizes();
}

const addressType = ref<"customerPrivat" | "customerBusiness">(
  "customerPrivat",
);

const userDefaultShippingAddress = computed(() => {
  return customerAddresses.value.filter(
    (add) => add.id === defaultShippingAddressId.value,
  );
});

const isShowAll = ref(false);
const toggleAddresses = computed(() =>
  isShowAll.value
    ? customerAddresses.value
    : customerAddresses.value.slice(0, 2),
);

onMounted(() => {
  try {
    if (!activeBillingAddress.value && defaultBillingAddressId.value) {
      setActiveBillingAddress({ id: defaultBillingAddressId.value });
    }
  } catch (error) {
    console.error("Error setting active billing address:", error);
  }
});
</script>

<template>
  <SharedDetailsSummary
    :title="t('checkout.chooseBillingAddress')"
    class="mb-3"
  >
    <div class="bg-white p-4 sm:px-6 sm:pt-1 sm:pb-6 relative">
      <div :selectedCardId="activeBillingAddress?.id" class="mt-5">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div
            v-if="isLoggedIn && customerAddresses.length > 0"
            id="newAdress"
            class="b b-scheppach-primary-500 rd cursor-pointer min-h-[185px] flex items-center justify-center transition bg-scheppach-primary-50 c-scheppach-primary-700 hover:c-scheppach-primary-300 duration-100"
            @click="
              {
                (newBillingAddress = !newBillingAddress), (isShowAll = false);
              }
            "
          >
            <p class="text-5">{{ t("checkout.addNewAddress") }}</p>
          </div>
          <div
            v-if="isLoggedIn && customerAddresses.length > 0"
            id="activeAddress"
            class="min-h-[185px] pt-8 px-6 relative b b-scheppach-primary-500 rd"
            :is-selectable="!activeBillingAddress?.id"
          >
            <span
              class="rd-br bg-scheppach-primary-500 px-3 pb-[3px] c-white absolute top-0 left-0"
            >
              <p v-if="!activeBillingAddress?.id">
                {{ t("account.myAccount.defaultAddress") }}
              </p>
              <p v-else>{{ t("checkout.selectedAddress") }}</p>
            </span>
            <div v-if="!!activeBillingAddress?.id">
              <p>{{ activeBillingAddress?.company }}</p>
              <p>
                {{ activeBillingAddress?.firstName }}
                {{ activeBillingAddress?.lastName }}
              </p>
              <p>{{ activeBillingAddress?.street }}</p>
              <p>
                {{ activeBillingAddress?.zipcode }}
                {{ activeBillingAddress?.city }}
              </p>
              <p>{{ activeBillingAddress?.country?.name }}</p>
              <p>{{ activeBillingAddress?.phoneNumber }}</p>
            </div>
            <p
              v-else
              class="flex justify-center items-center h-full text-lg c-scheppach-primary-700"
            >
              {{ t("checkout.noSelectedBillingAddress") }}
            </p>
          </div>
        </div>
        <div
          v-if="isLoggedIn && customerAddresses.length > 0"
          class="mt-5 mb-3"
        >
          <p class="c-scheppach-primary-500 select-none">
            {{ t("checkout.availableAddresses") }}
          </p>
          <hr class="bg-scheppach-primary-500 h-2px w-full" />
        </div>
        <SharedSelectableCardGroup
          ref="cardGroup"
          class="grid grid-cols-1 md:grid-cols-2 gap-5"
          :selected-card-id="activeBillingAddress?.id"
        >
          <SharedSelectableCard
            v-for="address in toggleAddresses"
            :id="address.id"
            :key="address.id"
            class="min-h-[185px] py-4 px-6"
            @click="setBillingAddress(address)"
          >
            <p>{{ address?.company }}</p>
            <p>{{ address?.firstName }} {{ address.lastName }}</p>
            <p>{{ address?.street }}</p>
            <p>{{ address?.zipcode }} {{ address.city }}</p>
            <p>{{ address?.country?.name }}</p>
            <p>{{ address?.phoneNumber }}</p>
          </SharedSelectableCard>
        </SharedSelectableCardGroup>
        <button
          v-if="customerAddresses.length > 2"
          class="mt-5 rd w-full md:w-[273px] h-[51px] b b-scheppach-primary-700 bg-scheppach-shades-0 hover:bg-scheppach-primary-50 c-scheppach-primary-700 text-[16px] uppercase"
          @click="isShowAll = !isShowAll"
        >
          {{ !isShowAll ? t("product.showAll") : t("product.showLess") }}
        </button>

        <div
          v-if="needsExtraPhoneNumber && isLoggedIn"
          class="mt-5 p-5 b b-scheppach-primary-500 rd"
        >
          <ScheppachTextInput
            v-model="billingAddress.extraPhoneNumber"
            :label="t('form.phoneNumber')"
            :placeholder="t('form.phonePlaceholder')"
            :required="true"
            :input-error="vb.extraPhoneNumber?.$error"
            :input-error-message="vb.extraPhoneNumber?.$errors[0]?.$message"
            @blur="vb.extraPhoneNumber?.$touch()"
          />
        </div>
      </div>

      <ScheppachSpinner v-if="isLoading['billingAddress']" />

      <div v-if="newBillingAddress" class="flex flex-col gap-3 mt-5">
        <div class="flex flex-row gap-4">
          <input
            id="shippingCustomerTypePrivate"
            v-model="addressType"
            class="customer-type-radio accent-scheppach-primary-500 w-[22px] h-[22px]"
            checked
            value="customerPrivat"
            name="customer-type"
            type="radio"
          />
          <label for="shippingCustomerTypePrivate" class="cursor-pointer">
            {{ t("checkout.customerTypePrivate") }}
          </label>
        </div>
        <div class="flex flex-row gap-4 mb-4">
          <input
            id="shippingCustomerTypeBusiness"
            v-model="addressType"
            class="customer-type-radio accent-scheppach-primary-500 w-[22px] h-[22px]"
            value="customerBusiness"
            name="customer-type"
            type="radio"
          />
          <label for="shippingCustomerTypeBusiness" class="cursor-pointer">
            {{ t("checkout.customerTypeBusiness") }}
          </label>
        </div>
        <SharedAddressForm
          :has-email="true"
          :state="billingAddress"
          :rules="sharedRules"
          :address-type="addressType"
        />

        <SharedWarningBox
          v-if="hasBillingValidationError"
          class="mt-10"
          :callback="proceedBillingAddress"
        />

        <div
          class="bg-scheppach-primary-500 hover:bg-scheppach-primary-700 p-2 c-white rd mt-10 cursor-pointer w-65 text-center"
          @click="addressValidation(true)"
        >
          {{ t("form.applyBillingAddress") }}
        </div>
      </div>
      <div class="mt-5">
        <ScheppachCheckbox
          v-model="differingShippingAddress"
          :label="t('form.differsFromDeliveryAddress')"
        />
      </div>
    </div>
  </SharedDetailsSummary>

  <SharedDetailsSummary
    v-if="differingShippingAddress"
    :title="t('checkout.chooseShipmentAddress')"
    class="mb-3"
  >
    <div class="bg-white p-4 sm:px-6 sm:pt-1 sm:pb-6 relative">
      <div v-if="isLoggedIn && customerAddresses.length > 0" class="mt-5">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div
            id="newAdress"
            class="b b-scheppach-primary-500 rd cursor-pointer min-w-[300px] min-h-[185px] flex items-center justify-center transition bg-scheppach-primary-50 c-scheppach-primary-700 hover:c-scheppach-primary-300 duration-100"
            @click="
              {
                (newShippingAddress = !newShippingAddress), (isShowAll = false);
              }
            "
          >
            <p class="text-5">{{ t("checkout.addNewAddress") }}</p>
          </div>
          <div
            class="min-w-[300px] min-h-[185px] pt-8 px-6 relative rd b b-scheppach-primary-500"
          >
            <span
              class="rd-br bg-scheppach-primary-500 px-3 pb-[3px] c-white absolute top-0 left-0"
            >
              <p v-if="!activeShippingAddress?.id">
                {{ t("account.myAccount.defaultAddress") }}
              </p>
              <p v-else>{{ t("checkout.selectedAddress") }}</p>
            </span>
            <template v-if="!activeShippingAddress?.id">
              <div
                v-for="address in userDefaultShippingAddress"
                :key="address.id"
              >
                <p>{{ address?.company }}</p>
                <p>{{ address?.firstName }} {{ address?.lastName }}</p>
                <p>{{ address?.street }}</p>
                <p>{{ address?.zipcode }} {{ address?.city }}</p>
                <p>{{ address?.country?.name }}</p>
                <p>{{ address?.phoneNumber }}</p>
              </div>
            </template>
            <div v-else>
              <p>{{ activeShippingAddress?.company }}</p>
              <p>
                {{ activeShippingAddress?.firstName }}
                {{ activeShippingAddress?.lastName }}
              </p>
              <p>{{ activeShippingAddress?.street }}</p>
              <p>
                {{ activeShippingAddress?.zipcode }}
                {{ activeShippingAddress?.city }}
              </p>
              <p>{{ activeShippingAddress?.country?.name }}</p>
              <p>{{ activeShippingAddress?.phoneNumber }}</p>
            </div>
          </div>
        </div>
        <div class="mt-5 mb-3">
          <p class="c-scheppach-primary-500 select-none">
            {{ t("checkout.availableAddresses") }}
          </p>
          <hr class="bg-scheppach-primary-500 h-2px w-full" />
        </div>

        <SharedSelectableCardGroup
          class="grid grid-cols-1 md:grid-cols-2 gap-5"
          :selected-card-id="activeShippingAddress?.id"
        >
          <SharedSelectableCard
            v-for="address in toggleAddresses"
            :id="address.id"
            :key="address.id"
            class="min-w-[300px] min-h-[185px] py-4 px-6"
            @click="setShippingAddress(address)"
          >
            <p>{{ address?.company }}</p>
            <p>{{ address?.firstName }} {{ address.lastName }}</p>
            <p>{{ address?.street }}</p>
            <p>{{ address?.zipcode }} {{ address.city }}</p>
            <p>{{ address?.country?.name }}</p>
            <p>{{ address?.phoneNumber }}</p>
          </SharedSelectableCard>
        </SharedSelectableCardGroup>

        <button
          v-if="customerAddresses.length > 2"
          class="mt-5 rd w-full md:w-[273px] h-[51px] b b-scheppach-primary-700 bg-scheppach-shades-0 hover:bg-scheppach-primary-50 c-scheppach-primary-700 text-[16px] uppercase"
          @click="isShowAll = !isShowAll"
        >
          {{ !isShowAll ? t("product.showAll") : t("product.showLess") }}
        </button>
      </div>

      <ScheppachSpinner v-if="isLoading['shippinAddress']" />

      <div v-if="newShippingAddress" class="flex flex-col gap-3 mt-5">
        <SharedAddressForm
          :has-email="false"
          :has-street-split="true"
          :state="shippingAddress"
          :rules="sharedRules"
        />

        <SharedWarningBox
          v-if="hasShippingValidationError"
          class="mt-10"
          :callback="proceedShippingAddress"
        />

        <div
          class="bg-scheppach-primary-500 hover:bg-scheppach-primary-700 p-2 c-white rd mt-10 cursor-pointer w-65 text-center"
          @click="addressValidation(false)"
        >
          {{ t("form.applyShippingAddress") }}
        </div>
      </div>
    </div>
  </SharedDetailsSummary>

  <SharedModal :controller="addressValidationController">
    <ScheppachAddressValidation
      :state="addressToValidate"
      :controller="addressValidationController"
      :require-country-name="true"
      :address-suggestions="addressSuggestions"
      @toggle-validation-complete="saveAddresses"
    />
  </SharedModal>
</template>
